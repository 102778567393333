import humps from 'humps';
import axios, {Method} from "axios";
import StorageService from "../service/StorageService";
import HttpStatusCode from "../constants/HttpErrorCode";
import {toastUtil} from "../utils/ToastUtil";
import {loginStore} from "../../pages/auth/login/LoginStore";
import createAuthRefreshInterceptor from "axios-auth-refresh";

export interface IApiResponse {
    readonly status: number;
    readonly body: any;
}

export interface IBodyError {
    readonly errorCode: number;
    readonly message: string;
    readonly messageCode?: string;
}
export function clearLocalStorage() {
    StorageService.removeToken();
    StorageService.removeRefreshToken();
    window.location.href = `/login?form=true${new URLSearchParams(window.location.search).get('username') ? `&username=${new URLSearchParams(window.location.search).get('username')}` : ''}${new URLSearchParams(window.location.search).get('redirectTo') ? `&redirectTo=${new URLSearchParams(window.location.search).get('redirectTo')}` : ''}${new URLSearchParams(window.location.search).get('service') ? `&service=${new URLSearchParams(window.location.search).get('service')}` : ''}`
}

let API_DOMAIN = window.env.REACT_APP_API_URL_DOMAIN

let apiRefreshToken = window.REACT_APP_API_DOMAIN + '/sso/v1/auth/refresh-token'
const refreshAuthLogic = (failedRequest: { config: { headers: any } }) => axios.post(apiRefreshToken, {
    'refreshToken': StorageService.getRefreshToken(),
    service: new URLSearchParams(window.location.search).get('service') ?? 'iss'
})
    .then(response => {
        StorageService.setToken(response.data.token);
        failedRequest.config.headers['Authorization'] = 'Bearer ' + response.data.token;
        return Promise.resolve();
    })
    .catch(function (error) {
    });

createAuthRefreshInterceptor(axios, refreshAuthLogic, {
    pauseInstanceWhileRefreshing: true
});
axios.interceptors.request.use((config) => {
    config.headers['Authorization'] = 'Bearer ' + StorageService.getToken();
    return config;
});

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (originalRequest.url === apiRefreshToken) {
            toastUtil.error('Phiên đăng nhập hết hạn', 2);
            setTimeout(() => {
                clearLocalStorage()
            }, 600)
        }
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                await createAuthRefreshInterceptor(axios, refreshAuthLogic);
                return axios(originalRequest);
            } catch (refreshError) {
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);


export async function getRequest(path: string): Promise<IApiResponse> {

    let newHeaders: any = {'Content-Type': 'application/json'}

    if (StorageService.isTokenExits()) {
        newHeaders = {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
            Authorization: 'Bearer ' + StorageService.getToken()
        }
    }
    return await axios.get(API_DOMAIN + path, {headers: newHeaders})
        .then(
            (response) => {
                const apiResponse: IApiResponse = {
                    status: response?.status,
                    body: humps.camelizeKeys(response?.data),
                };
                return apiResponse;
            },
            (error) => {
                let redirect: any = new URLSearchParams(window.location.search).get('redirectTo')
                let service: any = new URLSearchParams(window.location.search).get('service')

                if (error.response && error.response?.status === HttpStatusCode.UNAUTHORIZED) {
                    toastUtil.error('Phiên đăng nhập hết hạn', 2);
                    StorageService.removeToken();
                    loginStore.showForm = true
                    if (redirect) {
                        window.location.href = `${window.REACT_APP_DOMAIN_SSO}?redirectTo=${redirect ?? window.location.origin}&username=${new URLSearchParams(window.location.search).get('username')}${service ? `&service=${service}` : ''}&form=true`
                    }else {
                        window.location.href = `${window.REACT_APP_DOMAIN_SSO}?username=${new URLSearchParams(window.location.search).get('username')}${service ? `&service=${service}` : ''}&form=true`
                    }
                } else if (error.response && error.response?.status === HttpStatusCode.FORBIDDEN) {
                    toastUtil.error('Tài khoản của bạn không có quyền', 2);
                }
                let bodyError: IBodyError;
                try {
                    bodyError = {
                        errorCode: error.response?.data?.errorCode,
                        message: error.response?.data?.message,
                    }
                } catch (e) {
                    bodyError = {
                        errorCode: HttpStatusCode.UNKNOW_ERROR,
                        message: "Unknow error, please try again later"
                    }
                }

                const apiResponse: IApiResponse = {
                    status: error.response?.status,
                    body: bodyError
                };

                return apiResponse;
            },
        )
}


export async function postRequest(path: string, params: object, token?: any): Promise<IApiResponse> {
    return apiCall(path, "POST", params, token);
}

export async function deleteRequest(path: string, params: object, token?: any): Promise<IApiResponse> {
    return apiCall(path, "DELETE", params, token);
}

export async function putRequest(path: string, params: object, methodPATCH?: any, token?: any): Promise<IApiResponse> {
    return apiCall(path, methodPATCH ? 'PATCH' : "PUT", params, token);
}

export function apiCall(path: string, _method: Method = "POST", _params: object, token?: any): Promise<IApiResponse> {
    let newHeaders: any = {'Content-Type': 'application/json'}

    if (StorageService.isTokenExits()) {
        newHeaders = {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
            Authorization: 'Bearer ' + StorageService.getToken()
        }
    }


    return new Promise<IApiResponse>((resolve) => {
        axios({
            data: JSON.stringify(_params),
            headers: newHeaders,
            method: _method,
            url: API_DOMAIN + path
        })
            .then(function (response) {
                resolve({
                    status: response?.status,
                    body: humps.camelizeKeys(response?.data),
                });
            })
            .catch(function (error) {
                    if (error.response && (error.response?.status === HttpStatusCode.UNAUTHORIZED)) {
                        if (_method !== "DELETE") {
                            toastUtil.error('Phiên đăng nhập hết hạn', 2);
                        }
                        loginStore.showForm = true
                        StorageService.removeToken();
                        StorageService.removeRefreshToken();
                    } else if (error.response && error.response?.status === HttpStatusCode.FORBIDDEN) {
                        toastUtil.error('Tài khoản của bạn không có quyền', 2);
                    }

                    let bodyError: IBodyError;
                    try {
                        if (error.response && error.response?.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
                            bodyError = {
                                errorCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
                                message: "Internal server error, please try again later"
                            }
                        } else {
                            bodyError = {
                                errorCode: error.response?.data?.errorCode,
                                message: error.response?.data?.message,
                                messageCode: error.response?.data?.messageCode
                            }
                        }

                    } catch (e) {
                        bodyError = {
                            errorCode: HttpStatusCode.UNKNOW_ERROR,
                            message: "Unknow error, please try again later"
                        }
                    }

                    const apiResponse: IApiResponse = {
                        status: error.response?.status,
                        body: bodyError
                    };

                    resolve(apiResponse);
                }
            );

    });
}


