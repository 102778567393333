/* eslint-disable array-callback-return */
import {accountService} from "../auth/AccountService";
import {observable} from "mobx";
import {loginStore} from "../auth/login/LoginStore";
import {isTokenExpired, parseJwt, setCookie} from "../../common/utils/Utils";
import StorageService from "../../common/service/StorageService";
import Constants from "../../common/constants/Constants";


class ProfileStore {

    @observable dataInfo: any = {
        authenticate: false,
        exp: "",
        farmId: "",
        facilityId: "",
        roleName: '',
        name: '',
        farmOwner: false,
        iat: "",
        roles: "",
        userId: ""
    }
    @observable public isLoadingLogin: boolean = false;


    async getToken(val: any, redirectTo?: any, service?: any){
        try {
            if (!isTokenExpired(val.exp)) {
                loginStore.showForm = true
                loginStore.username = val.phone
                profileStore.isLoadingLogin = false
            } else {
                profileStore.isLoadingLogin = true
                // const result = await accountService.getToken(val.token)
                const result = await accountService.getRefreshToken({
                    refreshToken: val.refreshToken,
                    service: !Constants.SERVICE_LIST.includes(loginStore.service) ? null : loginStore.service
                })
                if (result.status === 200) {
                    let token: string = result.data.token;
                    let refreshToken: string = val.refreshToken;
                    StorageService.setRefreshToken(refreshToken)
                    StorageService.setToken(token)
                    parseJwt(token)
                    loginStore.listAccount && loginStore.listAccount.map((item) => {
                        if(item.username === val.username){
                            item.token = token
                            localStorage.setItem('dataSso', JSON.stringify({token: token, refreshToken: refreshToken}));
                            setCookie('dataSso', JSON.stringify({token: token, refreshToken: refreshToken}), Constants.TOKEN_EXPIRE_DAYS);
                            window.location.href = (loginStore.redirectTo && Constants.SERVICE_LIST.includes(loginStore.service)) ? `${loginStore.redirectTo}${loginStore.isCms ? '/cms/users' : ''}?token=${token}${loginStore.service !== 'life' ? `&refreshToken=${refreshToken}` : ''}`
                                : `${window.REACT_APP_DOMAIN_SSO}/profile?username=${val.username}`
                            window.addEventListener('load', () => {
                                profileStore.isLoadingLogin = false;
                            });
                            const response = {
                                data : {
                                    event: "ON_LOGIN",
                                    ssoData: {
                                        token: `${token}`,
                                        refreshToken: `${refreshToken}`
                                    }
                                }
                            };

                            window.sendMobile(JSON.stringify(response));
                        }
                    })
                    localStorage.setItem('listAccount', JSON.stringify(loginStore.listAccount))
                    profileStore.isLoadingLogin = false
                }else {
                    setTimeout(() => {
                        window.location.href = `/login?form=true${val.username ? `&username=${val.username}` : ''}${redirectTo ? `&redirectTo=${redirectTo}` : ''}${service ? `&service=${service}` : ''}`
                    }, 100);
                }
            }
        }
        catch (error: any) {

            setTimeout(() => {
                window.location.href = `/login?form=true${val.username ? `&username=${val.username}` : ''}${redirectTo ? `&redirectTo=${redirectTo}` : ''}${service ? `&service=${service}` : ''}`
            }, 100);
        }
    }

    async checkValidateToken(){
        if(StorageService.getToken()){
            const result = await accountService.checkToken()
            if(result.status === 200){
                profileStore.dataInfo = result.body
            }
        }
    }


}

export const profileStore = new ProfileStore()