import {postRequest, IApiResponse, getRequest, deleteRequest, putRequest} from "../../common/helpers/RequestHelper";
import {loginStore} from "./login/LoginStore";
import Constants from "../../common/constants/Constants";
import axios from "axios";

class AccountService {
    public login(username: string, password: string, service: any): Promise<IApiResponse> {
        return postRequest(`/sso/v1/auth/login`, {username, password, service});
    }

    public register(data: any): Promise<IApiResponse> {
        return postRequest(`/sso/v1/auth/register`, data);
    }
    public checkEmailRegister(data: any): Promise<IApiResponse> {
        return postRequest(`/sso/v1/auth/validate`, data);
    }
    public sendOTP(data: any): Promise<IApiResponse> {
        return postRequest(`/sso/v1/otp/send`, data);
    }
    public verifyUser(data: any): Promise<IApiResponse> {
        return postRequest(`/sso/v1/user/verify`, data);
    }
    public verifyOTP(data: any): Promise<IApiResponse> {
        return postRequest(`/sso/v1/otp/verify`, data);
    }
    public forgotPassword(data: any): Promise<IApiResponse> {
        return postRequest(`/sso/v1/user/forgot_password`, data);
    }
    public logOut(token: any): Promise<IApiResponse> {
        return deleteRequest('/sso/v1/auth/logout', {},  token);
    }

    public logOutAll(token: any): Promise<IApiResponse> {
        return deleteRequest('/sso/v1/auth/logout?logoutAll=true', {}, token);
    }

    public checkToken(): Promise<IApiResponse> {
        return getRequest(`/sso/v1/auth/valid-token`)
    }

   public getToken(token: any): Promise<IApiResponse> {
        return postRequest(`/sso/v1/auth/gettoken?service=${!Constants.SERVICE_LIST.includes(loginStore.service) ? 'farm' : loginStore.service}`, {}, token)
    }
    public getRefreshToken(data: any): Promise<any> {
        return axios.post(window.REACT_APP_API_DOMAIN + '/sso/v1/auth/refresh-token', data)
    }

    public getProfile(): Promise<IApiResponse> {
        return getRequest(`/sso/v1/user`)
    }
    
    public updateProfile(data: any, token: any): Promise<IApiResponse> {
        return putRequest(`/sso/v1/user`, data, false , token)
    }

    public changePass(data: any, token: any): Promise<IApiResponse> {
        return postRequest(`/sso/v1/user/change_password`, data, token)
    }
}

export const accountService = new AccountService();
